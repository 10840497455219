<template>
  <div
    v-if="showModal"
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog" style="max-width: 60%;" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Crear Guia</h5>
          <button
            type="button"
            class="close"
            @click="closeModal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="wrapper">
          <div class="card-body modal-body">
            <div class="card pt-2 p-2">
              <div class="row">
                <div class="col-md-8">
                  <h3 style="font-size: 15px; text-align: center">
                    <strong>
                      GUIA UNICA PARA TRANSPORTE FLUVIAL DE
                      <br />
                      Y SUS DERIVADOS
                    </strong>
                  </h3>
                </div>
                <div class="col-md-2 float-md-right">
                  <h6>
                    <span class="badge badge-success">Estado: Disponible</span>
                    <!-- <span class="badge badge-primary" v-if="estadoGuia == 2"
                      >Estado: Usada</span
                    >
                    <span class="badge badge-warning" v-if="estadoGuia == 6"
                      >Estado: Sin Imprimir</span
                    > -->
                  </h6>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 mt-4">
                  <img
                    src="/img/frontera-pegaso-ermes.png"
                    style="width: 280px"
                  />
                </div>
                <div class="col-md-4">
                  <label for="nGuia" class="form-label">N° GUIA</label>
                  <p
                    class="form-control text-center fw-bold fs-5 bg-custom-disabled"
                    style="pointer-events: none;"
                  >
                    {{ guias ? guias.guia.numero : "N/A" }} -
                    {{ guias ? guias.guia.digito_verificacion : "N/A" }}
                  </p>
                </div>
                <div class="col-md-4">
                  <label for="nGuia" class="form-label">TIPO PRODUCTO</label>
                  <p
                    class="form-control text-center fw-bold fs-5 bg-custom-disabled"
                    style="pointer-events: none;"
                  >
                    {{ guias ? guias.producto.nombre : "N/A" }}
                  </p>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="row" style="font-size: 12px">
                  <div class="col-md-6">
                    <label for="nGuia" class="form-label"
                      >LUGAR DE EXPEDICION</label
                    >
                    <p
                      class="form-control text-center fw-bold fs-5 bg-custom-disabled"
                      style="pointer-events: none;"
                    >
                      {{ guias ? guias.solicitud_fluvial.sitio.nombre : "N/A" }}
                    </p>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="fecha_expedicion1"
                      >FECHA Y HORA DE EXPEDICION</label
                    >
                    <div class="row ml-1">
                      <input
                        type="date"
                        v-model="formEnvio.fecha_expedicion"
                        style="font-size: 13px"
                        class="form-control form-control-sm col-md-6"
                        :class="
                          $v.formEnvio.fecha_expedicion.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                      <input
                        v-model="formEnvio.hora_expedicion"
                        type="text"
                        class="form-control form-control-sm col-md-6"
                        style="font-size: 13px"
                        v-mask="{
                          mask: '99:99',
                          hourFormat: '24',
                        }"
                        :class="
                          $v.formEnvio.hora_expedicion.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </div>
                  </div>
                </div>
                <div class="row" style="font-size: 12px">
                  <div class="form-group" :class="'col-md-6'">
                    <label for="campo_productor"
                      >TERMINAL FLUVIAL/PUERTO DE ORIGEN</label
                    >
                    <v-select
                      :class="[
                        $v.formEnvio.campo_productor_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="formEnvio.campo_productor_id"
                      :options="listasForms.campoProductor"
                      label="descripcion"
                      placeholder="Planta o Campo Productor"
                      class="form-control form-control-sm p-0"
                      :reduce="(option) => option.numeracion"
                    >
                    </v-select>
                  </div>
                  <div class="form-group" :class="'col-md-6'">
                    <label for="remision">N° DE LIQUIDACION</label>
                    <input
                      type="text"
                      v-model="formEnvio.remision"
                      class="form-control form-control-sm"
                      style="font-size: 13px"
                      placeholder="Orden de Cargue"
                      :class="
                        $v.formEnvio.remision.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="row" style="font-size: 12px">
                  <div class="form-group" :class="'col-md-8'">
                    <label for="campo_productor">DESPACHADO A</label>
                    <v-select
                      :class="[
                        $v.formEnvio.despachado_a_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="formEnvio.despachado_a_id"
                      :options="listasForms.despachadoA"
                      label="descripcion"
                      placeholder="Planta o Campo Productor"
                      class="form-control form-control-sm p-0"
                      :reduce="(option) => option.numeracion"
                    >
                    </v-select>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="campo_productor"
                      >CODIGO SICOM / NIT DESPACHADO A</label
                    >
                    <input
                      type="text"
                      v-model="formEnvio.codigo_destino"
                      style="font-size: 13px"
                      placeholder="Codigo Dane"
                      class="form-control form-control-sm"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="nGuia" class="form-label">DIRECCION</label>
                    <p
                      class="form-control text-center fw-bold fs-5 bg-custom-disabled"
                      style="pointer-events: none;"
                    >
                      {{
                        (this.formEnvio.direccion_destino = {}
                          ? this.formEnvio.direccion_destino
                          : "N/A")
                      }}
                    </p>
                  </div>

                  <div class="col-md-6">
                    <label for="nGuia" class="form-label">CIUDAD</label>
                    <p
                      class="form-control text-center fw-bold fs-5 bg-custom-disabled"
                      style="pointer-events: none;"
                    >
                      {{
                        (this.formEnvio.ciudadNombre = {}
                          ? this.formEnvio.ciudadNombre
                          : "N/A")
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- Datos de conductor Y vehiculo-->
              <div class="small-box bg-light p-2">
                <div class="row" style="font-size: 12px">
                  <div class="form-group col-md-4">
                    <label for="conductor">NOMBRE DEL CAPITAN</label>
                    <input
                      type="text"
                      v-model="conductor.nombre"
                      style="font-size: 13px"
                      placeholder="Nombre Conductor"
                      class="form-control form-control-sm"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="campo_productor">CÉDULA</label>
                    <input
                      type="text"
                      v-model="formEnvio.cedula_conductor"
                      style="font-size: 13px"
                      placeholder="Cédula Conductor"
                      class="form-control form-control-sm"
                      :class="
                        $v.formEnvio.cedula_conductor.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @change="buscarConductores"
                    />
                  </div>
                  <div class="col-md-4">
                    <label for="nGuia" class="form-label">BARCAZA</label>
                    <p
                      class="form-control text-center fw-bold fs-5 bg-custom-disabled"
                      style="pointer-events: none;"
                    >
                      {{ guias ? guias.barcaza.nombre : "N/A" }}
                    </p>
                  </div>
                  <div class="form-group" :class="'col-md-5'">
                    <label for="campo_productor">EMPRESA TRANSPORTADORA</label>
                    <v-select
                      :class="[
                        $v.formEnvio.empresa_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="formEnvio.empresa_id"
                      :options="listasForms.empresas"
                      label="razon_social"
                      placeholder="empresa"
                      class="form-control form-control-sm p-0"
                      :reduce="(option) => option.id"
                    >
                    </v-select>
                  </div>
                  <div class="form-group col-md-3">
                    <label for="cabezote">NOMBRE DEL REMOLCADOR</label>
                    <input
                      type="text"
                      v-model="formEnvio.remolcador"
                      style="font-size: 13px"
                      placeholder="Nombre Remolcador"
                      class="form-control form-control-sm"
                      :class="
                        $v.formEnvio.remolcador.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @change="buscarRemolcador()"
                    />
                  </div>
                  <div class="form-group" :class="'col-md-2'">
                    <label for="remision">PATENTE REMOLCADOR</label>
                    <input
                      type="text"
                      v-model="formEnvio.patente"
                      class="form-control form-control-sm"
                      style="font-size: 13px"
                      placeholder="patente"
                      :class="
                        $v.formEnvio.patente.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div class="form-group" :class="'col-md-2'">
                    <label for="remision">N° DE VIAJE</label>
                    <input
                      type="number"
                      v-model="formEnvio.viaje"
                      class="form-control form-control-sm"
                      style="font-size: 13px"
                      placeholder="patente"
                      :class="
                        $v.formEnvio.viaje.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>
                </div>
              </div>
              <!-- Datos de sitio cargue y descargue -->
              <div class="small-box bg-light p-2">
                <div class="row" style="font-size: 12px">
                  <div class="col-md-6">
                    <label for="nGuia" class="form-label"
                      >LUGAR DE ORIGEN</label
                    >
                    <p
                      class="form-control text-center fw-bold fs-5 bg-custom-disabled"
                      style="pointer-events: none;"
                    >
                      {{ guias ? guias.solicitud_fluvial.sitio.nombre : "N/A" }}
                    </p>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="nGuia" class="form-label"
                      >FECHA Y HORA DE SALIDA</label
                    >

                    <div class="row ml-1">
                      <input
                        type="date"
                        v-model="formEnvio.fecha_salida"
                        style="font-size: 13px"
                        class="form-control form-control-sm col-md-6"
                        :class="
                          $v.formEnvio.fecha_salida.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                      <input
                        v-model="formEnvio.hora_salida"
                        type="time"
                        class="form-control form-control-sm col-md-6"
                        style="font-size: 13px"
                        :class="
                          $v.formEnvio.hora_salida.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="sitio_destino">SITIO DESTINO:</label>

                    <v-select
                      :class="[
                        $v.formEnvio.sitio_destino_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="formEnvio.sitio_destino_id"
                      :options="listasForms.sitios"
                      label="nombre"
                      placeholder="nombre"
                      class="form-control form-control-sm p-0"
                      :reduce="(option) => option.id"
                    >
                    </v-select>
                  </div>

                  <div class="form-group" :class="'col-md-2'">
                    <label for="campo_productor">TEMPORADA</label>
                    <select
                      v-model="formEnvio.temporada_id"
                      :disabled="formEnvio.sitio_destino_id == null"
                      class="form-control form-control-sm p-0"
                      @input="buscarRuta()"
                    >
                      <option value="" disabled
                        >Seleccione una temporada</option
                      >
                      <option
                        v-for="temporada in listasForms.temporadas"
                        :key="temporada.numeracion"
                        :value="temporada.numeracion"
                      >
                        {{ temporada.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label for="nGuia" class="form-label"
                      >Horas de Vigencia</label
                    >
                    <p class="form-control text-center fw-bold fs-5">
                      {{
                        this.formEnvio.horas_vigencia
                          ? this.formEnvio.horas_vigencia
                          : ""
                      }}
                    </p>
                  </div>
                  <div class="form-group col-md-3">
                    <label for="hora_adicional">HORA ADICIONAL</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="formEnvio.hora_adicional"
                    >
                      <option value>Seleccione...</option>
                      <option
                        v-for="horaAdicional in guias.horasAdicionales"
                        :key="horaAdicional.numeracion"
                        :value="horaAdicional.numeracion"
                      >
                        {{ horaAdicional.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <!-- Sellos - Volumenes -->
              <div class="small-box bg-light p-2">
                <div class="row" style="font-size: 12px">
                  <div class="form-group col-md-12">
                    <label for="sellos">DESCRIPCION DEL PRODUCTO</label>
                    <v-select
                      :class="[
                        $v.formEnvio.sellos.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="formEnvio.sellos"
                      placeholder="Sellos"
                      label="numero"
                      :options="listasForms.sellos"
                      class="form-control form-control-sm p-0"
                      multiple
                    ></v-select>
                    <div class="error">
                      Seleccione los Sellos
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="gov">GOV</label>
                    <div class="input-group">
                      <input
                        type="number"
                        v-model="formEnvio.gov"
                        style="font-size: 13px"
                        class="form-control form-control-sm"
                        id="gov"
                      />
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="gsv">GSV</label>
                    <div class="input">
                      <input
                        type="number"
                        v-model="formEnvio.gsv"
                        style="font-size: 13px"
                        class="form-control form-control-sm"
                        step="any"
                        :class="
                          $v.formEnvio.gsv.$invalid ? 'is-invalid' : 'is-valid'
                        "
                      />
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="nsv">NSV</label>
                    <input
                      type="number"
                      v-model="formEnvio.nsv"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      step="any"
                      :class="
                        $v.formEnvio.nsv.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>
                  <div class="form-group col-md-12">
                    <label for="observaciones">OBSERVACIONES</label>
                    <textarea
                      v-model="formEnvio.observaciones"
                      cols="30"
                      rows="4"
                      class="form-control form-control-sm"
                      :class="
                        $v.formEnvio.observaciones.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    ></textarea>
                    <div
                      style="font-size: 13px"
                      class="error"
                      v-if="!$v.formEnvio.observaciones.required"
                    >
                      Ingrese una Observación
                    </div>
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="row" style="font-size: 12px">
                  <div class="form-group col-md-3">
                    <label for="temp">ANALISIS DE LAB (TEMP°)</label>
                    <input
                      type="number"
                      v-model="formEnvio.analisis_laboratorio"
                      style="font-size: 13px"
                      min="50"
                      max="250"
                      class="form-control form-control-sm"
                      :class="
                        $v.formEnvio.analisis_laboratorio.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label>A.P.I</label>
                    <input
                      type="number"
                      v-model="formEnvio.api"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      :class="
                        $v.formEnvio.api.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label for="bsw">B.S.W</label>
                    <input
                      type="number"
                      v-model="formEnvio.bsw"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      :class="
                        $v.formEnvio.bsw.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label for="azufre">S%</label>
                    <input
                      type="number"
                      v-model="formEnvio.azufre"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      :class="
                        $v.formEnvio.azufre.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>
                  <div class="form-group col-md-12">
                    <label for="novedades">NOVEDADES - PROCESO STAND BY</label>
                    <v-select
                      :class="[
                        $v.formEnvio.novedades.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="formEnvio.novedades"
                      placeholder="Novedades"
                      label="descripcion"
                      :options="listasForms.novedadesSta"
                      class="form-control form-control-sm p-0"
                      multiple
                    ></v-select>
                  </div>
                </div>
              </div>
              <!-- Botones -->
              <div class="small-box bg-light p-2">
                <div style="font-size: 12px">
                  <div style="display: flex; justify-content: flex-end;">
                    <button
                      type="button"
                      v-show="!$v.formEnvio.$invalid"
                      class="btn btn-success btn-custom"
                      @click="save()"
                    >
                      Guardar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";

import {
  required,
  minLength,
  maxLength,
  helpers,
  numeric,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";
import $ from "jquery";

export default {
  name: "DetGuiasFluvialForm",
  components: {
    vSelect,
  },
  data() {
    return {
      vehiculo: {
        nombre: "", // Usamos nombre en vez de placa
        remolcador_id: null,
      },
      conductor: {
        nombre: "",
      },
      formEnvio: {
        viaje: null,
        patente: null,
        fecha_salida: null,
        hora_salida: null,
        campo_productor_id: null,
        remolcador_id: null,
        remolcador: null,
        empresa_id: null,
        sitio_cargue_id: null,
        sitio_destino_id: null,
        temporada_id: null,
        ciudadDestino: null,
        cedula_conductor: null,
        direccion_destino: null,
        codigo_destino: null,
        despachado_a_id: null,
        remision: null,
        gov: null,
        guia_id: null,
        gsv: null,
        analisis_laboratorio: null,
        api: null,
        bsw: null,
        azufre: null,
        nsv: null,
        producto_liquido_id: null,
        fecha_expedicion: null,
        hora_expedicion: null,
        sitioDestinoId: null,
        sellos: null,
        observaciones: null,
        novedades: null,
        ciudadNombre: null,
        horas_vigencia: null,
        ruta_id: null,
        hora_adicional: null,
        temporadass: null,
      },
      showModal: false,
      guias: [],
      listasForms: {
        campoProductor: [],
        despachadoA: [],
        empresas: [],
        sitios: [],
        temporadas: [],
        sellos: [],
        novedadesSta: [],
      },
      totalGSV: 0,
      totalNSV: 0,
      estadoGuia: null,
      temporadasEncontradas: [],
      rutaEncontrada: {},
    };
  },
  validations() {
    return {
      formEnvio: {
        fecha_salida: { required },
        campo_productor_id: { required },
        hora_salida: { required },
        remolcador_id: { required },
        remolcador: { required },
        empresa_id: { required },
        sitio_cargue_id: { required },
        sitio_destino_id: { required },
        temporada_id: { required },
        ciudadDestino: null,
        cedula_conductor: { required },
        direccion_destino: null,
        codigo_destino: null,
        despachado_a_id: { required },
        remision: { required },
        gov: null,
        guia_id: { required },
        gsv: { required },
        analisis_laboratorio: { required },
        api: { required },
        bsw: { required },
        azufre: { required },
        nsv: { required },
        producto_liquido_id: { required },
        fecha_expedicion: { required },
        hora_expedicion: { required },
        sitioDestinoId: { required },
        sellos: { required },
        observaciones: { required },
        novedades: { required },
        ciudadNombre: {},
        horas_vigencia: {},
        ruta_id: { required },
        hora_adicional: {},
        patente: { required },
        viaje: { required },
      },
    };
  },

  methods: {
    openModalGuia(guias) {
      this.guias = guias;
      this.formEnvio.ciudad_origen_id = this.guias.solicitud_fluvial.sitio.ciudad_id;
      this.formEnvio.tipo_producto_id = this.guias.producto_id;

      this.formEnvio.guia_id = this.guias.guia_id;
      this.formEnvio.gsv =
        (parseFloat(this.guias.gsv_frontera) || 0) +
        (parseFloat(this.guias.gsv_trafigura) || 0);

      this.formEnvio.sitio_cargue_id = this.guias.solicitud_fluvial.sitio_id;
      this.getSellos();
      this.showModal = true;
    },

    async buscarConductores() {
      let resultadoExacto = {};
      if (!this.formEnvio.cedula_conductor) {
        await this.$swal({
          icon: "error",
          title: "Por favor, ingresa el número de documento del conductor",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        return;
      }

      try {
        const response = await axios.get("/api/admin/operarios/lista", {
          params: {
            numero_documento: this.formEnvio.cedula_conductor.trim(), // Envía el documento
          },
        });

        // Filtra en el frontend para obtener coincidencia exacta
        resultadoExacto = response.data.find(
          (operario) =>
            operario.numero_documento === this.formEnvio.cedula_conductor.trim()
        );

        if (resultadoExacto) {
          this.formEnvio.operador_id = resultadoExacto.id;
          this.conductor.nombre = resultadoExacto.nombre;
          this.conductor.numero_documento = resultadoExacto.numero_documento;
          this.conductor.celular = resultadoExacto.celular_principal;
        } else {
          this.formEnvio.cedula_conductor = null;
          await this.$swal({
            icon: "error",
            title: "No se encontró un conductor con ese número de documento",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        }
      } catch (error) {
        await this.$swal({
          icon: "error",
          title: "Error al buscar el conductor. Intenta nuevamente.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.formEnvio.cedula_conductor = null;
      }
    },

    async buscarRemolcador() {
      let remolcadorExacto = {};
      const response = await axios.get(`/api/admin/remolcadores/lista`, {
        params: {
          nombre: this.formEnvio.remolcador.trim(),
        },
      });

      remolcadorExacto = response.data.find(
        (remolcador) => remolcador.nombre === this.formEnvio.remolcador.trim()
      );

      if (remolcadorExacto) {
        this.formEnvio.remolcador_id = remolcadorExacto.id;

        await this.$swal({
          icon: "success",
          title: "Remolcador encontrado",
          text: "El remolcador existe y ha sido asignado correctamente.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      } else {
        this.formEnvio.remolcador = null;
        this.formEnvio.remolcador_id = null;
        await this.$swal({
          icon: "error",
          title: "Remolcador no encontrado",
          text: "No se encontró un remolcador.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
    async getCampoProd() {
      axios.get("/api/lista/215").then((response) => {
        this.listasForms.campoProductor = response.data;
      });
    },
    async getDespachadoA() {
      axios.get("/api/lista/216").then((response) => {
        this.listasForms.despachadoA = response.data;
      });
    },
    async getTemporadas() {
      axios.get("/api/lista/217").then((response) => {
        this.listasForms.temporadas = response.data;
      });
    },
    async getEmpresas() {
      axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },
    async getSitios() {
      axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.sitios = response.data;
      });
    },
    async getNovedades() {
      axios.get("/api/lista/218").then((response) => {
        this.listasForms.novedadesSta = response.data;
      });
    },

    getSellos() {
      axios
        .get(`/api/hidrocarburos/sellos/lista`, {
          params: { sitio_id: this.formEnvio.sitio_cargue_id },
        })
        .then((response) => {
          this.listasForms.sellos = response.data;
        });
    },

    updateDireccionYCiudad() {
      const sitioSeleccionado = this.guias.sitios.find(
        (sitio) => sitio.id === this.formEnvio.sitioDestinoId
      );

      if (sitioSeleccionado) {
        this.formEnvio.direccion_destino = sitioSeleccionado.dirreccion;

        this.formEnvio.ciudadDestino = sitioSeleccionado.ciudad.nombre;
      }
    },

    closeModal() {
      this.rutaEncontrada = [];
      this.guias = [];
      this.vehiculo = {};
      this.conductor = {};
      this.formEnvio = {};
      this.estadoGuia = null;
      this.totalGSV = 0;
      this.totalNSV = 0;
      this.temporadasEncontradas = [];
      this.rutaEncontrada = [];
      this.showModal = false;
    },

     buscarRuta() {
      let data = {
        origen_id: this.guias?.solicitud_fluvial?.sitio?.id || null,
        destino_id: this.formEnvio.sitio_destino_id || null,
        temporada_id: this.formEnvio.temporada_id || null,
      };
      

/*       // Reiniciamos valores antes de la consulta
      this.formEnvio.direccion_destino = null;
      this.formEnvio.ciudadDestino = null;
      this.formEnvio.ciudadNombre = null;
      this.formEnvio.horas_vigencia = null;
      this.formEnvio.ruta_id = null;
 */
       axios({
        method: "POST",
        url: "/api/hidrocarburos/solicitudesFluviales/buscarRuta",
        data: data,
       }).then((response) => {

     

        console.log(response);
        
        
      });

    /*   console.log("Respuesta de la API:", response.data);

      this.ruta = response.data.ruta;
      this.temporadas = response.data.temporadas;


      if (response.data?.ruta) {
        this.ruta = response.data.ruta;
        this.formEnvio.direccion_destino = this.ruta?.destino?.dirreccion || "";
        this.formEnvio.ciudadDestino = this.ruta?.destino?.ciudad?.id || null;
        this.formEnvio.ciudadNombre = this.ruta?.destino?.ciudad?.nombre || "";
      }

  

        await this.$swal({
          title: "Éxito",
          text: "Ruta y temporadas encontradas",
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      */

    },

    save() {
      try {
        const response = axios.post(
          "/api/hidrocarburos/solicitudesFluviales/guardarGuia",
          this.formEnvio
        );
        this.$swal({
          title: "Éxito",
          text: "Los datos se guardaron correctamente",
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.showModal = false;
      } catch (error) {
        const errorMessage =
          error.response?.data?.message ||
          "Ocurrió un error al guardar los datos";
        this.$swal({
          title: "Error",
          text: errorMessage,
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
  },
  beforeMount() {
    this.getSitios();
    this.getCampoProd();
    this.getDespachadoA();
    this.getEmpresas();
    this.getTemporadas();
    this.getNovedades();
  },
};
</script>

<style scoped>
.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
.bg-sobrante {
  background-color: rgb(131, 197, 222);
}
.bg-cumple {
  background-color: rgb(171, 219, 171);
}
.modal-body {
  max-height: 800px;
  overflow-y: auto;
}
.bg-custom-disabled {
  background-color: rgb(232, 236, 238);
  color: rgb(117, 126, 135);
}
.btn-custom {
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 5px;
  width: 120px;
  height: 50px;
}
</style>
